/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '../../services/api';

import construction from '../../assets/img/construction.svg';
import logo from '../../assets/img/logo.png';

import { Container } from './styles';

export default function Manutencao() {
  useEffect(() => {
    console.log('Bem-vindo');
  }, []);

  return (
    <Container>
      <div className="header">
        <img src={logo} className="image" />
        <p className="title">AD Valentina 1</p>
      </div>
      <img src={construction} className="svg" />
      <p className="title">Seja bem-vindo ao APP da AD Valentina 1</p>
      <p className="subtitle">
        Estamos preparando o melhor para você. 29/09/2020. Logo mais
        atualizações. Enquanto isso desfrute do nosso aplicativo na playstore.
      </p>
      <a
        className="btn"
        href="https://play.google.com/store/apps/details?id=com.adpb.advalentina1.membro&hl=pt_BR"
      >
        Baixar aplicativo
      </a>
    </Container>
  );
}
