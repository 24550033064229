import React from 'react';

import { Container } from './styles';

export default function notFound() {
  return (
    <Container>
      <h1>404</h1>
    </Container>
  );
}
