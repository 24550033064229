import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useAuth } from '../../Context/AuthContext';

import api from '../../services/api';

import logo from '../../assets/img/logo.png';
import logoSvg from '../../assets/img/login.svg';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

export default function SignIn() {
  const { setSigned } = useAuth();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  function handleSubmit({ email, password }) {
    setLoading(true);
    api
      .post('/sessions', {
        email,
        password,
      })
      .then(res => {
        toast.success('Login feito com sucesso.');
        setLoading(false);
        history.push('/home');

        const { user, token } = res.data;

        api.defaults.headers.Authorization = `Bearer ${token}`;

        localStorage.setItem('@AdValentina1:user', user);
        localStorage.setItem('@AdValentina1:token', `Bearer ${token}`);

        setSigned(true);
      })
      .catch(() => {
        toast.warning('Email ou senha errada.');
        setLoading(false);
      });
  }

  return (
    <>
      <div className="header">
        <img src={logo} className="image" alt="Ad Valentina 1" />
        <p className="title">AD Valentina 1</p>
      </div>
      <img src={logoSvg} className="svg" />
      <Form schema={schema} onSubmit={handleSubmit}>
        <Input name="email" type="email" placeholder="Seu e-mail" />
        <Input
          name="password"
          type="password"
          placeholder="Sua senha secreta"
        />

        <button type="submit">{loading ? 'Carregando...' : 'Acessar'}</button>
        <Link to="/registrar">Criar conta gratuita</Link>
      </Form>
    </>
  );
}
