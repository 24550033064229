import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;

  .image {
    height: 80px;
    width: 80px;
  }

  .title {
    font-weight: 600;
    color: #4d4d4d;
    font-size: 28px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left: 5%;
  }

  .svg {
    width: 72%;
  }

  input {
    width: 90%;
    border: none;
    border-radius: 50px;
    background: #f1f1f1;
    padding: 5%;
    margin: 1.5% 0;
  }

  button {
    width: 50%;
    height: 8%;
    border: none;
    border-radius: 50px;
    background: #5fe59e;
    color: #fff;
    font-size: 20px;
  }
`;

export const Card = styled(Link)`
  max-width: 450px;
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 110px;
  background: #e3e9f5;
  border-radius: 10px;
  margin: 20px 0 0 0;
  border-left: ${props =>
    !props.desativar ? '10px solid #2CBF6C' : '10px solid #C60D0D'};

  justify-content: center;
  align-items: center;
`;
