import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding: 9% 5%;

  .image {
    height: 80px;
    width: 80px;
  }

  .title {
    font-weight: 600;
    color: #000;
    font-size: 26px;
  }

  .subtitle {
    color: #4d4d4d;
    font-weight: 600;
    font-size: 16px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .svg {
    width: 72%;
  }

  input {
    width: 90%;
    border: none;
    border-radius: 50px;
    background: #f1f1f1;
    padding: 5%;
    margin: 1.5% 0;
  }

  button {
    width: 50%;
    height: 8%;
    border: none;
    border-radius: 50px;
    background: #5fe59e;
    color: #fff;
    font-size: 20px;
  }

  .btn {
    border: none;
    background: #4747ff;
    color: #fff;
    font-size: 12px;
    font-family: Poppins, sans-serif;
    padding: 10px 20px;
    border-radius: 50px;
    cursor: pointer;
  }
`;
