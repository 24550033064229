import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import Home from '../pages/Home';
import Evento from '../pages/Evento';
import SignUp from '../pages/SignUp';
import Manutencao from '../pages/Manutencao';

import notFound from '../pages/notFound';

import AuthProvider from '../Context/AuthContext';

export default function Routes() {
  return (
    <AuthProvider>
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/registrar" exact component={SignUp} />
        <Route path="/home" exact isPrivate component={Home} />
        <Route path="/evento/:id" exact isPrivate component={Evento} />

        <Route path="/" component={notFound} />
      </Switch>
    </AuthProvider>
  );
}
