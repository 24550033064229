import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api from '../../services/api';

import logo from '../../assets/img/logo.png';

const schema = Yup.object().shape({
  nome: Yup.string().required('O nome é obrigatório'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
  birth_date: Yup.string().required('A data de nascimento é obrigatória'),
  congregacao: Yup.string().required('A congregação é obrigatória'),
});

export default function SignUp() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  function handleSubmit({ nome, email, congregacao, password, birth_date }) {
    setLoading(true);
    api
      .post('/users', {
        nome,
        email,
        password,
        birth_date,
        congregacao,
      })
      .then(() => {
        toast.success('Registro feito com sucesso.');
        setLoading(false);
        history.push('/');
      })
      .catch(() => {
        toast.warning('Email já existente');
        setLoading(false);
      });
  }

  return (
    <>
      <div className="header">
        <img src={logo} className="image" alt="Ad Valentina 1" />
        <p className="title">AD Valentina 1</p>
      </div>
      <Form schema={schema} onSubmit={handleSubmit}>
        <Input name="nome" placeholder="Nome completo" />
        <Input
          name="birth_date"
          placeholder="Data de nascimento"
          type="date"
          label="Data de nascimento"
        />
        <Input name="email" type="email" placeholder="Seu e-mail" />
        <Input name="congregacao" placeholder="Sua congregação" />
        <Input
          name="password"
          type="password"
          placeholder="Sua senha secreta"
        />

        <button type="submit">{loading ? 'Carregando...' : 'Cadastrar'}</button>
        <Link to="/">Já tenho login</Link>
      </Form>
    </>
  );
}
