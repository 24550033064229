/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { toast } from 'react-toastify';

import { Link } from 'react-router-dom';

import api from '../../services/api';

import logo from '../../assets/img/logo.png';

import { Container, Card, Button } from './styles';

export default function Home() {
  const [event, setEvent] = useState({});
  const [desativado, setDesativado] = useState(false);
  const [registered, setRegistered] = useState();
  const [full, setFull] = useState();
  const [token, setToken] = useState();

  const { id } = useParams();

  useEffect(() => {
    api.get(`/events/${id}`).then(res => {
      console.log(res.data.date);
      res.data.date = parseISO(res.data.date);
      res.data.date = format(res.data.date, "EEEE', 'dd'/'MM' às ' HH:mm'h'", {
        locale: pt,
      });

      setEvent(res.data);

      setRegistered(res.data.registered);
      setFull(res.data.full);
      setToken(res.data.token);
    });
  }, []);

  function handleMeRegistra() {
    setDesativado(true);
    // console.log(user);
    if (registered) {
      api
        .delete(`/registrations/event/${event.id}`)
        .then(res => {
          console.log(res.data);

          setRegistered(false);
          setDesativado(false);

          setToken(null);
          toast.success('Desinscrição feita com sucesso.');
        })
        .catch(err => {
          setDesativado(false);
          toast.warning('Desinscrição feita com sucesso.');

          return console.log(err);
        });
    } else {
      api
        .post(`/registrations/event/${event.id}`)
        .then(res => {
          console.log(res.data);
          setRegistered(true);
          setDesativado(false);
          setToken(res.data.token);

          toast.success('Inscrição feita com sucesso.');
        })
        .catch(err => {
          console.log(err);
          setDesativado(false);
          toast.error('Inscrição mal sucedido');
        });
    }
  }

  return (
    <Container>
      <div className="header">
        <Link
          to="/home"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '10px 0px',
          }}
        >
          <MdKeyboardArrowLeft color="#4d4d4d" size={30} />
        </Link>
      </div>
      <p className="title">Eventos</p>
      {token && (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p className="sub-t">Seu código é: </p>
          <p className="sub-ta">{token}</p>
        </div>
      )}
      <Card>
        <img src={event.banner?.url} className="image-t" />
        <p style={{ fontWeight: 500 }}>{event.title}</p>
        <p style={{ fontSize: 12 }}>{event.caption}</p>
        <p style={{ padding: '2% 0' }}>{event.date}</p>
        <p style={{ alignSelf: 'flex-start', fontSize: 14 }}>Descrição:</p>
        <p style={{ fontSize: 11 }}>{event.description}</p>
        <Button
          background={registered || full || !event.check_in ? true : false}
          onClick={handleMeRegistra}
          disabled={
            !event.check_in || desativado || (full && !registered)
              ? true
              : false
          }
        >
          {!event.check_in
            ? 'Inscrições encerradas'
            : registered
            ? full
              ? 'Desregistrar e Lotado'
              : 'Desregistrar'
            : full
            ? 'Lotado'
            : 'Participar'}
        </Button>
      </Card>
    </Container>
  );
}
